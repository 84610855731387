<template>
  <div class="stayPageWrap en">
    <m-layout-bar>
      <div class="stayEnWrap">
        <div class="bannerWrap">
          <img :src="require('../assets/image/stay_en/banner.png')" alt />
        </div>
        <div class="contentWrap">
          <div class="layoutLeft">
            <div class="asideTopWrap">
              <div class="titleWrap">
                <img :src="require('../assets/image/stay_en/iconLeft.png')" alt />
                <span>Live leisurely</span>
              </div>
              <ul class="tabWrap">
                <li
                  v-for="(item,index) in dataInfo"
                  :key="index"
                  class="item"
                  :class="{'active':item.id==cur}"
                  data-index="0"
                  @click="tabClickHandle(index,item,$event)"
                >
                  <router-link to="/stay_en/roomInfo">{{item.title}}</router-link>
                </li>
              </ul>
            </div>
            <div class="asideBottomWrap">
              <span class="title">Reservation telephone</span>
              <span class="detail">400-661-5666</span>
            </div>
          </div>
          <div class="layoutRight">
            <router-view @book="bookHandle"></router-view>
          </div>
        </div>
        <!-- 遮罩 -->
        <div class="iMask" v-show="showMaskFlag">
          <div class="maskWrap">
            <img class="code" :src="require('../assets/image/food_en/code2.png')" alt />
            <img
              class="close"
              @click="closeMask"
              :src="require('../assets/image/stay/close.png')"
              alt
            />
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar_en";

import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      activeIndex: "0",
      banners: [
        "g1_p1.png",
        "g1_p2.png",
        "g1_p3.png",
        "g2_banner.png",
        "g1_banner.png",
        "g1_p3.png"
      ],
      showMaskFlag: false,
      dataInfo: new Array(),
      cur: ""
    };
  },
  mounted() {
    this.getData(config.stayUrl_en);
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  methods: {
    async getData(url) {
      try {
        let res = await axios.get(url);
        let tempData = {};
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            tempData = JSON.parse(JSON.stringify(res.data.data));
            this.dataInfo = tempData;
            this.cur = tempData[0].id;
          }
          // console.log("res.data.data:", this.dataInfo);
        }
        return tempData;
      } catch (e) {
        console.log("res err:", e);
      }
    },
    tabClickHandle(id, obj, event) {
      console.log("in click:", obj);
      this.cur = obj.id;
      this.$router.push({
        name: "roomInfo",
        params: {
          id: id,
          item: JSON.stringify(obj)
        }
      });
    },
    bookHandle() {
      this.showMaskFlag = true;
    },
    closeMask() {
      this.showMaskFlag = false;
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.iMask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 99999;
  .maskWrap {
    position: absolute;
    width: 299px;
    height: 345px;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
  .code {
    width: 305px;
    height: 343px;
    margin-bottom: 10px;
    cursor: default;
  }
  .close {
    cursor: pointer;
  }
}

.stayEnWrap {
  margin-bottom: 100px;
  border-bottom: 1px solid transparent;
  // 公用

  > .bannerWrap {
    position: relative;
    > img {
      width: 100%;
      height: 427px;
    }
  }
  > .contentWrap {
    width: 1400px;
    margin: 47px auto 0 auto;
    // background: red;
    > .layoutLeft {
      display: inline-block;
      width: 353px;
      vertical-align: top;
      > .asideTopWrap {
        min-height: 435px;
        background: $main-gray;
        > .titleWrap {
          position: relative;
          width: 353px;
          height: 90px;
          box-sizing: border-box;
          padding: 35px 0 15px 36px;

          > img {
            vertical-align: bottom;
            margin-right: 12px;
          }
          > span {
            font-size: 30px;
            color: $font-grayDarkDD;
          }
        }
        > .tabWrap {
          padding-left: 15px;

          > .item {
            > a {
              display: block;
              width: 308px;
              height: 67px;
              line-height: 70px;
              border-bottom: 1px solid $font-grayDark;
              font-size: 24px;
              padding-left: 5px;
              color: $font-grayDark;
              &:hover {
                color: rgba(0, 214, 144, 0.3);
              }
            }
            a:focus,
            a:active {
              color: $bg-greengreen2;
            }
          }
          > .item.active {
            a {
              color: $bg-greengreen2;
            }
          }
        }
      }
      > .asideBottomWrap {
        height: 232px;
        color: $main-white;
        padding-left: 28px;
        margin-top: 64px;
        background: $bg-greengreen2;
        overflow: hidden;
        > .title {
          display: block;
          font-size: 24px;
          margin-top: 75px;
        }
        > .detail {
          display: block;
          font-size: 30px;
          font-weight: bold;
          margin-top: 25px;
        }
      }
    }
    > .layoutRight {
      display: inline-block;
      width: 965px;
      min-height: 500px;
      vertical-align: top;
      // background: red;nth
      margin-left: 82px;
    }
  }
}
</style>
